// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-data-destruction-js": () => import("./../../../src/pages/data-destruction.js" /* webpackChunkName: "component---src-pages-data-destruction-js" */),
  "component---src-pages-e-waste-collection-js": () => import("./../../../src/pages/e-waste-collection.js" /* webpackChunkName: "component---src-pages-e-waste-collection-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-pos-cloud-management-js": () => import("./../../../src/pages/pos/cloud-management.js" /* webpackChunkName: "component---src-pages-pos-cloud-management-js" */),
  "component---src-pages-pos-delivery-integration-js": () => import("./../../../src/pages/pos/delivery-integration.js" /* webpackChunkName: "component---src-pages-pos-delivery-integration-js" */),
  "component---src-pages-pos-existing-pos-integration-js": () => import("./../../../src/pages/pos/existing-pos-integration.js" /* webpackChunkName: "component---src-pages-pos-existing-pos-integration-js" */),
  "component---src-pages-pos-js": () => import("./../../../src/pages/POS.js" /* webpackChunkName: "component---src-pages-pos-js" */),
  "component---src-pages-pos-multisite-management-js": () => import("./../../../src/pages/pos/multisite-management.js" /* webpackChunkName: "component---src-pages-pos-multisite-management-js" */),
  "component---src-pages-pos-online-ordering-js": () => import("./../../../src/pages/pos/online-ordering.js" /* webpackChunkName: "component---src-pages-pos-online-ordering-js" */),
  "component---src-pages-pos-payment-system-js": () => import("./../../../src/pages/pos/payment-system.js" /* webpackChunkName: "component---src-pages-pos-payment-system-js" */),
  "component---src-pages-pos-qr-visual-menu-js": () => import("./../../../src/pages/pos/qr-visual-menu.js" /* webpackChunkName: "component---src-pages-pos-qr-visual-menu-js" */),
  "component---src-pages-pos-self-order-kiosk-js": () => import("./../../../src/pages/pos/self-order-kiosk.js" /* webpackChunkName: "component---src-pages-pos-self-order-kiosk-js" */),
  "component---src-pages-pricing-js": () => import("./../../../src/pages/pricing.js" /* webpackChunkName: "component---src-pages-pricing-js" */),
  "component---src-pages-support-js": () => import("./../../../src/pages/support.js" /* webpackChunkName: "component---src-pages-support-js" */),
  "component---src-pages-wifi-captive-portal-cloud-management-js": () => import("./../../../src/pages/wifi-captive-portal/cloud-management.js" /* webpackChunkName: "component---src-pages-wifi-captive-portal-cloud-management-js" */),
  "component---src-pages-wifi-captive-portal-commercial-grade-wifi-js": () => import("./../../../src/pages/wifi-captive-portal/commercial-grade-wifi.js" /* webpackChunkName: "component---src-pages-wifi-captive-portal-commercial-grade-wifi-js" */),
  "component---src-pages-wifi-captive-portal-customised-look-feel-js": () => import("./../../../src/pages/wifi-captive-portal/customised-look-feel.js" /* webpackChunkName: "component---src-pages-wifi-captive-portal-customised-look-feel-js" */),
  "component---src-pages-wifi-captive-portal-email-and-mobile-verification-js": () => import("./../../../src/pages/wifi-captive-portal/email-and-mobile-verification.js" /* webpackChunkName: "component---src-pages-wifi-captive-portal-email-and-mobile-verification-js" */),
  "component---src-pages-wifi-captive-portal-landing-page-js": () => import("./../../../src/pages/wifi-captive-portal/landing-page.js" /* webpackChunkName: "component---src-pages-wifi-captive-portal-landing-page-js" */),
  "component---src-pages-wifi-captive-portal-marketing-integration-js": () => import("./../../../src/pages/wifi-captive-portal/marketing-integration.js" /* webpackChunkName: "component---src-pages-wifi-captive-portal-marketing-integration-js" */),
  "component---src-pages-wifi-captive-portal-social-media-login-js": () => import("./../../../src/pages/wifi-captive-portal/social-media-login.js" /* webpackChunkName: "component---src-pages-wifi-captive-portal-social-media-login-js" */),
  "component---src-pages-wifi-captive-portal-what-is-skypos-wifi-js": () => import("./../../../src/pages/wifi-captive-portal/what-is-skypos-wifi.js" /* webpackChunkName: "component---src-pages-wifi-captive-portal-what-is-skypos-wifi-js" */)
}

